import React, { useState, createContext } from 'react';

const GlobalContext = createContext();

export const GlobalProvider = ({ children }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  return (
    <GlobalContext.Provider value={[isModalOpen, setIsModalOpen]}>
      {children}
    </GlobalContext.Provider>
  );
};

export default GlobalContext;
